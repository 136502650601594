import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
//import Carousel1 from '../images/carousel1.jpg'
//import Carousel2 from '../images/carousel2.jpg'
import Carousel3 from '../images/carousel3.jpg'
import Right from '../images/icon-right.svg'

const Carousel = ({ taller }) => (
<section id="carousel" className="carousel">
      <div className="container">
        <div id="owlCarouselDiscover" className="owl-carousel owl-theme owl-carousel-discover">
          <div className="item">
            {/*<img src={Carousel1} alt="" className="inline-photo show-on-scroll" style={{ display: "none" }} />*/}
            <GatsbyImage
              image={taller.images[0].image.childImageSharp.gatsbyImageData}
              alt={taller.title}    
              className="service-image inline-photo show-on-scroll"                
            />  
            <div className="caption">
              <p className="title">{taller.title}</p>
              <p className="desc"  dangerouslySetInnerHTML={{ __html: taller.about.substring(0,taller.about.indexOf('.'))+"..." }}/>
              <a href="/" className="link linkModal" data-modal data-rel="#modal">
                <span>Leer más</span>
                <img src={Right} alt="" />
              </a>
            </div>
          </div>

          <div className="item">
            {/*<img src={Carousel2} alt="" className="inline-photo show-on-scroll" style={{display: "none" }} />*/}
            <GatsbyImage
              image={taller.images[1].image.childImageSharp.gatsbyImageData}
              alt={taller.title}    
              className="service-image inline-photo show-on-scroll"                
            />  
            <div className="caption">
              <p className="title">Garantía, calidad y experiencia Bosch Car Service</p>
              <a href={ "https://www.boschcarservice.com/es/es/descubrenos/nuestros-compromisos/" } target="_blank" rel="noreferrer" className="link">
                <span>Conócenos</span>
                <img src={Right} alt="" />
              </a>
            </div>
          </div>

          <div className="item">
            <img src={Carousel3} alt="" className="inline-photo show-on-scroll" />
            <div className="caption">
              <p className="title red">¿Te interesan nuestros servicios?</p>
              <a data-modal data-rel="#modal-widget" className="btn btnType2 small btnContactUs_ linkModal" href={ "https://www.boschcarservice.com/es/es/pide-cita#/booking/classic/landing?customerId="+taller.customerId } target="_blank" rel="noreferrer">Pide cita online</a>
            </div>
          </div>

        </div>
      </div>
    </section>
)
export default Carousel