import React from "react"
//import { Link } from "gatsby"
import Logo from '../images/logo.png'
import Open from "../images/icon-menu.svg"

const Header = ({ taller }) => (
    <header className="header">
    <div className="top">
      <div className="container">
        <button type="button" id="btnOpenMenu" className="btnIcon btnOpenNav">
          <img src={Open} alt="open" />
        </button>

        <nav className="navMain">
          <p className="tit">Menú</p>
          <ul>
            <li><a href="#empresa" className="btn btnEmpresa btnNavAnchor">Empresa</a></li>
            <li><a href="#servicios" className="btn btnServicios btnNavAnchor">Servicios</a></li>
            <li><a href="#promociones" className="btn btnPromociones btnNavAnchor">Promociones</a></li>
            <li><a href="#contacto" className="btn btnContacto btnNavAnchor">Contacto</a></li>
          </ul>
        </nav>
      </div>
    </div>
      
    <div className="bottom">
      <div className="container">
        <div className="flex">
          <img className="logo" src={Logo} alt="Bosch" />
          <p className="name">{taller.title}</p>
        </div>
      </div>
    </div>
  </header>
)
export default Header