import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Modal = ({ taller, id }) => (
    <div id={id} className="modal">
        <a href="#close" className="close" rel="modal:close">×</a>
        <div className="wrapper">
            <div className="content">
                <div className="info big">
                    <p className="title">Política de Cookies</p>
                    <p className="desc"  dangerouslySetInnerHTML={{ __html: taller.footer.cookiePolicy }} />
                </div>
            </div>    
            <div className="image">
                <GatsbyImage
                    image={taller.image.childImageSharp.gatsbyImageData}
                    alt={taller.title}    
                    className=""                
                  />  
            </div>
        </div>
    </div>
)
export default Modal